import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

import { Footer } from "@components";
import { CookieConsent } from "@components/CookieConsent";
import Header from "@components/header";
import { events, track } from "@shared/tracking";
import { pickByLocale } from "@lib/utils";
import type { Locale } from "../../i18n-config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IMainLayout {
  children: React.ReactNode;
  isFullScreen?: boolean;
  isFooter?: boolean;
  isBlack? : boolean;
  hideGet? : boolean;
}

export const MainLayout = ({ children, isFullScreen = false, isFooter = true, isBlack = false, hideGet = false }: IMainLayout) => {
  const locale = (useRouter().locale ?? "en") as Locale;

  const description = pickByLocale(
    {
      en: "Book unique experiences in Barcelona, Marrakech, and Lisbon with beder. Discover the best activities and hotels through videos from other travelers. Press play, book, and enjoy the journey!",
      es: "Reserva experiencias únicas en Barcelona, Marrakech y Lisboa con beder. Descubre las mejores actividades y hoteles a través de vídeos de otros viajeros. ¡Dale al play, reserva y disfruta del viaje!",
      fr: "Réservez des expériences uniques à Barcelone, Marrakech et Lisbonne avec beder. Découvrez les meilleures activités et hôtels à travers des vidéos d’autres voyageurs. Appuyez sur play, réservez et profitez du voyage!",
      ru: "Бронируйте уникальные впечатления в Барселоне, Марракеше и Лиссабоне с beder. Открывайте лучшие развлечения и отели через видео от других путешественников. Нажмите «Play», забронируйте и наслаждайтесь путешествием!"
    },
    locale
  );

  useEffect(() => {
    const isPageView = Cookies.get("is-page-view");

    if (isPageView === "false") {
      Cookies.remove("is-page-view");
      return;
    }

    const search = new URLSearchParams(window.location.search);
    const section = search.has("section")
      ? search.get("section")!
      : window.location.pathname.endsWith("experiences")
      ? "experiences"
      : window.location.pathname.endsWith("places-to-visit")
      ? "places-to-visit"
      : window.location.pathname.endsWith("hotels")
      ? "hotels"
      : "";

    const cityId = window.location.pathname.includes("spotprofile")
      ? window.location.pathname
          .split("/")
          .filter(
            (segment) => !!segment && !["en", "es", "fr"].includes(segment)
          )[1]
      : "";

    track(events.PAGE_VIEW, {
      ...(section && { section }),
      ...(cityId && { city_id: cityId }),
    });
  }, []);

  return (
    <>
      <Head>
        <title>Beder</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta httpEquiv="Content-Language" content="en,es,fr"></meta>
        <meta http-equiv="Content-Language" content="en,es,fr"></meta>
        <meta name="description" content={description} />
        <meta name="keywords" content="Travel experiences, Hotel bookings, Destination guides, Adventure travel, Local attractions, Unique accommodations, Vacation planning, Travel inspiration, Experiential travel, Hidden gems, Cultural experiences, Leisure activities, Trip planning, Travel recommendations, Sightseeing spots, Wanderlust destinations, Boutique hotels, Off-the-beaten-path adventures, Authentic experiences, Sustainable travel"/>

        <meta
          property="og:image"
          content="https://beder-sales.s3.eu-west-3.amazonaws.com/prod/styles/thumbnail/thumbnail.jpg"
          data-addsearch="no_crop"
        ></meta>
          <link href='https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' type='text/css'/>

      </Head>
      
      <div className={"overflow-clip" + (isBlack ? ' bg-black' : '')}>
        {/* NAVBAR */}

        <Header />
        <ToastContainer position="top-center" />
        {/* CHILDREN */}
        {children}
        {/* FOOTER */}
        {!isFullScreen && isFooter && (
          <>
            <Footer hideGet={hideGet} />
          </>
        )}
      </div>
      <CookieConsent />
    </>
  );
};
