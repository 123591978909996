export const dictionaries = {
  en: {
    navigation: {
      website: "main <span class='text-primary-color'>website</span>",
      bstudio: "<span class='text-primary-color'>b</span>studio",
      app: "the <span class='text-primary-color'>app</span>",
      about: "about <span class='text-primary-color'>us</span>",
      part: "be <span class='text-primary-color'>part</span> of",
    },
    home: {
      headings: {
        title: "TRAVEL IS ABOUT EXPERIENCES",
        subtitle1: "Book the best things to do in your destination",
        subtitle2: "<b>Watch, compare & book</b>",
      },
      top_places:
        "OUR TOP PLACES",
      top_experiences:
        "SOME OF OUR <span class='text-primary-color'>TOP EXPERIENCES</span>",
    },
    spotprofile: {
      title: "Discover and book the best of",
      places: "places to visit",
      filter_by: "filter by",
      apply: "Apply",
      apply_filters: "Apply filters",
      clear_all: "Clear all",
      places_in: "Places To Visit in",
      gallery_view: "Gallery view",
      map: {
        title: "Map",
        to_visit: "to visit",
      },
      experiences: {
        title: "experiences",
        in: "Experiences in",
        filters: {
          categories: "Categories",
          sort_by: "Sort by",
          sort_by_options: {
            price_asc: "Price (highest first)",
            price_desc: "Price (lowest first)",
            popularity: "Popularity",
          },
        },
        video: {
          more_people: "more people",
          more_person: "more person",
          watching: "watching this experience",
        },
      },
      hotels: {
        title: "hotels",
        in: "Hotels in",
        filters: {
          stars: "Stars",
          average_cost: "Average cost",
          score: "Score",
          sort_by: "Sort by",
          sort_by_options: {
            price_asc: "Price (highest first)",
            price_desc: "Price (lowest first)",
            score_asc: "Score (high to low)",
            score_desc: "Score (low to high)",
          },
          score_options: {
            pleasent: "Pleasent",
            good: "Good",
            very_good: "Very good",
            super: "Super",
          },
        },
      },
    },
    footer: {
      join: "join the community",
      discover: "and discover more",
      get_app: "Get the app",
      available_on: "App available on",
      main_website: "main website",
      about_us: "about us",
      be_part_of: "be part of",
      blog: "blog",
      contact_us: "contact us",
      privacy_policy: "privacy policy",
      cookies_policy: "cookies policy",
      terms_and_conditions: "Terms and Conditions",
      follow_us: "follow us on",
      languages: "languages",
      work_partners: "working with top travel booking companies",
      theapp: "the app",
    },
    activities: {
      free_cancelation: "free cancelation",
      check_hotel: "check hotel",
      reviews: "opinions",
      night: "night",
      about_experience: "About this experience",
      duration: "Duration",
      included: "Included",
      not_included: "Not included",
      meeting_point: "Meeting point",
      book: "book",
      bookings_in_last_24h: "Bookings in the last 24h",
      likely_to_sell_out: "Likely to sell out",
      from: "from",
      live_it_for: "Live it for",
      free: "free",
    },
  },
  es: {
    navigation: {
      website: "sitio <span class='text-primary-color'>web</span>",
      bstudio: "<span class='text-primary-color'>b</span>studio",
      app: "la <span class='text-primary-color'>app</span>",
      about: "sobre <span class='text-primary-color'>nosotros</span>",
      part: "forma <span class='text-primary-color'>parte</span>",
    },
    home: {
      headings: {
        title: "VIAJAR ES VIVIR EXPERIENCIAS",
        subtitle1:
          "Reserva las mejores actividades en tu destino",
        subtitle2: "<b>Dale al play, compara y reserva</b>",
      },
      top_places: "LUGARES TOP",
      top_experiences:
        "ALGUNAS <span class='text-primary-color'>EXPERIENCIAS TOP</span>",
    },
    spotprofile: {
      title: "Descubre y reserva lo mejor de",
      places: "lugares a visitar",
      filter_by: "filtrar por",
      apply: "Aplicar",
      apply_filters: "Aplicar filtros",
      clear_all: "Limpiar todo",
      places_in: "Lugares A Visitar en",
      gallery_view: "Vista de galería",
      map: {
        title: "Mapa",
        to_visit: "para visitar",
      },
      experiences: {
        title: "experiencias",
        in: "Experiencias en",
        filters: {
          categories: "Categorías",
          sort_by: "Ordenar por",
          sort_by_options: {
            price_asc: "Precio (más alto primero)",
            price_desc: "Precio (más bajo primero)",
            popularity: "Popularidad",
          },
        },
        video: {
          more_people: "más personas",
          more_person: "más persona",
          watching: "mirando esta experiencia",
        },
      },
      hotels: {
        title: "hoteles",
        in: "Hoteles en",
        filters: {
          stars: "Estrellas",
          average_cost: "Coste medio",
          score: "Puntuación",
          sort_by: "Ordenar por",
          sort_by_options: {
            price_asc: "Precio (más alto primero)",
            price_desc: "Precio (más bajo primero)",
            score_asc: "Puntuación (de alta a baja)",
            score_desc: "Puntuación (de baja a alta)",
          },
          score_options: {
            pleasent: "Agradable",
            good: "Bueno",
            very_good: "Muy bueno",
            super: "Super",
          },
        },
      },
    },
    footer: {
      join: "uneta a la comunidad",
      discover: "y descubre el mundo",
      get_app: "descargar la app",
      available_on: "Disponible en",
      main_website: "sitio web",
      about_us: "sobre nosotros",
      be_part_of: "forma parte de",
      blog: "blog",
      contact_us: "contacto",
      privacy_policy: "política de privacidad",
      cookies_policy: "política de cookies",
      terms_and_conditions: "Términos y Condiciones",
      follow_us: "síguenos en",
      languages: "idiomas",
      work_partners:
        "trabajando con las mejores compañías de reservas de viajes",
        theapp: "la app",
    },
    activities: {
      free_cancelation: "cancelación gratis",
      check_hotel: "ver hotel",
      reviews: "opiniones",
      night: "noche",
      about_experience: "Sobre la experiencia",
      duration: "Duración",
      included: "Incluido",
      not_included: "No incluido",
      meeting_point: "Punto de encuentro",
      book: "reservar",
      bookings_in_last_24h: "Reservas en las últimas 24h",
      likely_to_sell_out: "Probablemente se agote",
      from: "desde",
      live_it_for: "Vívelo por",
      free: "gratis",
    },
  },
  fr: {
    navigation: {
      website: "site <span class='text-primary-color'>web</span>",
      bstudio: "<span class='text-primary-color'>b</span>studio",
      app: "l'<span class='text-primary-color'>app</span>",
      about: "à propos <span class='text-primary-color'>de nous</span>",
      part: "fair <span class='text-primary-color'>partie</span>",
    },
    home: {
      headings: {
        title: "VOYAGER, C’EST VIVRE DES EXPÉRIENCES",
        subtitle1:
          "Réservez les meilleures choses à faire dans votre destination",
        subtitle2: "<b>Appuie sur play, compare et réserve</b>",
      },
      top_places:
        "NOS MEILLEURS LIEUX",
      top_experiences:
        "QUELQUES-UNS DE NOS <span class='text-primary-color'>MEILLEURES EXPÉRIENCES</span>",
    },
    spotprofile: {
      title: "Découvrir et réserver le meilleur de",
      places: "lieux à visiter",
      filter_by: "filtrer par",
      apply: "Appliquer",
      apply_filters: "Appliquer les filtres",
      clear_all: "Tout effacer",
      places_in: "Lieux À Visiter à",
      gallery_view: "Vue de la galerie",
      map: {
        title: "Carte",
        to_visit: "à visiter",
      },
      experiences: {
        title: "expériences",
        in: "Expériences à",
        filters: {
          categories: "Catégories",
          sort_by: "Trier par",
          sort_by_options: {
            price_asc: "Prix (du plus élevé au plus bas)",
            price_desc: "Prix (du plus bas au plus élevé)",
            popularity: "Popularité",
          },
        },
        video: {
          more_people: "plus de personnes",
          more_person: "plus de personne",
          watching: "regardent cette expérience",
        },
      },
      hotels: {
        title: "hôtels",
        in: "Hôtels à",
        filters: {
          stars: "Étoiles",
          average_cost: "Coût moyen",
          score: "Score",
          sort_by: "Trier par",
          sort_by_options: {
            price_asc: "Prix (du plus élevé au plus bas)",
            price_desc: "Prix (du plus bas au plus élevé)",
            score_asc: "Score (du plus haut au plus bas)",
            score_desc: "Score (du plus bas au plus haut)",
          },
          score_options: {
            pleasent: "Agréable",
            good: "Bon",
            very_good: "Très bon",
            super: "Super",
          },
        },
      },
    },
    footer: {
      join: "joindre la communauté",
      discover: "et découvrir le monde",
      get_app: "télécharger l'application",
      available_on: "App disponible sur",
      main_website: "site web",
      about_us: "à propos de nous",
      be_part_of: "faire partie de",
      blog: "blog",
      contact_us: "contact",
      privacy_policy: "politique de confidentialité",
      cookies_policy: "politique de cookies",
      terms_and_conditions: "Termes et Conditions",
      follow_us: "suivez-nous sur",
      languages: "langues",
      work_partners:
        "travailler avec les meilleures compagnies de réservation de voyages",
        theapp: "l'app",
    },
    activities: {
      free_cancelation: "annulation gratuite",
      check_hotel: "Voir l'Hôtel",
      reviews: "avis",
      night: "nuit",
      about_experience: "À propos de l'expérience",
      duration: "Durée",
      included: "Inclus",
      not_included: "Non inclus",
      meeting_point: "Point de rencontre",
      book: "réserver",
      bookings_in_last_24h: "Réservations dans les dernières 24 heures",
      likely_to_sell_out: "Probablement épuisé",
      from: "à partir de",
      live_it_for: "Vivre pour",
      free: "gratuit",
    },
  },
  ru: {
    navigation: {
      website: "главный <span class='text-primary-color'>сайт</span>",
      bstudio: "<span class='text-primary-color'>b</span>студия",
      app: "<span class='text-primary-color'>приложение</span>",
      about: "о <span class='text-primary-color'>нас</span>",
      part: "станьте <span class='text-primary-color'>частью</span>",
    },
    home: {
      headings: {
        title: "ПУТЕШЕСТВИЯ — ЭТО О ВПЕЧАТЛЕНИЯ",
        subtitle1: "Забронируйте лучшие развлечения для вашего путешествия",
        subtitle2: "<b>Смотрите, сравнивайте и бронируйте</b>",
      },
      top_places: "Наши лучшие направления",
      top_experiences: "НЕКОТОРЫЕ ИЗ НАШИХ <span class='text-primary-color'>ЛУЧШИХ ВПЕЧАТЛЕНИЙ</span>",
    },
    spotprofile: {
      title: "Откройте и забронируйте лучшее в",
      places: "Места для посещения",
      filter_by: "фильтровать по",
      apply: "Применить",
      apply_filters: "Применить фильтры",
      clear_all: "Очистить все",
      places_in: "Места для посещения в",
      gallery_view: "Просмотр галереи",
      map: {
        title: "Карта",
        to_visit: "для посещения",
      },
      experiences: {
        title: "Приключения",
        in: "Впечатления в",
        filters: {
          categories: "Категории",
          sort_by: "Сортировать по",
          sort_by_options: {
            price_asc: "Цена (сначала высокая)",
            price_desc: "Цена (сначала низкая)",
            popularity: "Популярность",
          },
        },
        video: {
          more_people: "Еще смотрят это впечатление",
          more_person: "еще один человек",
          watching: "смотрят это впечатление",
        },
      },
      hotels: {
        title: "отели",
        in: "Отели в",
        filters: {
          stars: "Звезды",
          average_cost: "Средняя стоимость",
          score: "Оценка",
          sort_by: "Сортировать по",
          sort_by_options: {
            price_asc: "Цена (сначала высокая)",
            price_desc: "Цена (сначала низкая)",
            score_asc: "Оценка (от высокой к низкой)",
            score_desc: "Оценка (от низкой к высокой)",
          },
          score_options: {
            pleasent: "Приятно",
            good: "Хорошо",
            very_good: "Очень хорошо",
            super: "Супер",
          },
        },
      },
    },
    footer: {
      join: "присоединяйтесь к сообществу",
      discover: "и откройте больше",
      get_app: "Скачать приложение",
      available_on: "Приложение доступнов",
      main_website: "главный сайт",
      about_us: "о нас",
      be_part_of: "Стать частью",
      blog: "блог",
      contact_us: "свяжитесь с нами",
      privacy_policy: "политика конфиденциальности",
      cookies_policy: "политика использования файлов cookie",
      terms_and_conditions: "Условия и положения",
      follow_us: "следите за нами в",
      languages: "языки",
      work_partners: "работаем с лучшими компаниями по бронированию путешествий",
      theapp: "приложение",
    },
    activities: {
      free_cancelation: "бесплатная отмена",
      check_hotel: "проверить отель",
      reviews: "отзывы",
      night: "ночь",
      about_experience: "О впечатлении",
      duration: "Длительность",
      included: "Включено",
      not_included: "Не включено",
      meeting_point: "Место встречи",
      book: "забронировать",
      bookings_in_last_24h: "Бронирования за последние 24 часа",
      likely_to_sell_out: "Вероятно, скоро закончатся",
      from: "от",
      live_it_for: "Проживите это за",
      free: "бесплатно",
    },
  },
  
};
