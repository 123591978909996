import { dictionaries } from "@lib/dictionaries";
import { useRouter } from "next/router";
import { Locale } from "../../i18n-config";

type NestedKeyOf<T extends object> = {
  [K in keyof T & (string | number)]: T[K] extends object
    ? `${K}` | `${K}.${NestedKeyOf<T[K]>}`
    : `${K}`;
}[keyof T & (string | number)];

type DictionaryKeys = NestedKeyOf<(typeof dictionaries)[Locale]>;

export const useTranslation = () => {
  const { locales = [], defaultLocale, ...nextRouter } = useRouter();
  const locale = (
    locales.includes(nextRouter.locale || "")
      ? nextRouter.locale
      : defaultLocale
  ) as Locale;

  return {
    t: (term: DictionaryKeys): string => {
      const translation = term.split(".").reduce((acc, cur) => {
        //console.log("Current acc:", acc);
        //console.log("Current cur:", cur);
        // @ts-ignore
        return acc && acc[cur] !== undefined ? acc[cur] : undefined;
      }, dictionaries[locale]);

      return typeof translation === "string" ? translation : term;
    },
    locale,
  };
};
