"use client";

import { useState } from "react";
import Image from "next/image";
import hand from "../assets/hand.png";
import Link from "next/link";
import LocaleSwitcher from "./locale-switcher";
import { dangerouslySetInnerHTML } from "@lib/utils";
import CurrencySwitcher from "./currency-switcher";
import { useTranslation } from "react-i18next";

export default function MenuburgerButton({
  items,
}: {
  items: {
    text: string;
    link: string;
  }[];
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {t} = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const downloadApp = () => {
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

    const playStoreUrl =
      "https://play.google.com/store/apps/details?id=com.bederapp";
    const appStoreUrl =
      "https://apps.apple.com/app/beder-l-explore-travel/id1609301338";

    const appUrl = isAndroid ? playStoreUrl : appStoreUrl;

    window.open(appUrl, "_blank", "noreferrer");
  };

  return (
    <div>
      <button
        className="p-2 text-gray-700 w-7 md:hidden"
        onClick={toggleMenu}
        aria-label="menu"
      >
        {isMenuOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-black w-7 h-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 12h16M4" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-black w-7 h-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>

      {isMenuOpen && (
        <div
          className="absolute left-0 w-full h-screen z-10 pr-[10%] cursor-pointer"
          onClick={() => setIsMenuOpen(false)}
        >
          <ul className="bg-white md:bg-transparent flex flex-col md:flex-row justify-end md:space-x-6 md:space-y-0 py-4 px-8 md:px-4 gap-4 whitespace-nowrap uppercase font-light md:font-normal">
            {items.map(({ text, link }, idx) => (
              <li
                key={idx}
                className="border-b md:border-0 border-primary-color py-2"
              >
                <Link href={link}>
                  <p dangerouslySetInnerHTML={dangerouslySetInnerHTML(text)} />
                </Link>
              </li>
            ))}
            <li className="border-b md:border-0 border-primary-color py-2">
              <button className="uppercase" onClick={downloadApp}>
                download <span className="text-primary-color">the app</span>
              </button>
            </li>
            <li className="flex justify-between py-2">
              <p className="md:hidden">{t("LANGUAGE")}</p>
              <div>
                <LocaleSwitcher />
              </div>
            </li>
            <li className="flex justify-between py-2">
              <p className="md:hidden">{t("CURRENCY")}</p>
              <div>
                <CurrencySwitcher />
              </div>
            </li>
          </ul>
          <div className="md:hidden flex flex-col justify-between items-center gap-y-2 rounded-br-[32px] pt-6 pb-2 bg-[#9BFFEF]">
            <div className="flex flex-col justify-center items-center p-4">
              <Image src={hand} alt="hand" className="" width={50} />
              <div className="uppercase text-lg py-2">
                <h4>life is short,</h4>
                <h4>travel hard</h4>
              </div>
            </div>
            <p className="text-xs font-light text-[#5A5959]">
              © {new Date().getFullYear()} beder app | All Rights Reserved
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
